import React from "react"
import { Container, Row, Col, Alert } from "reactstrap"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const ContactDiv = styled.div`
    margin-top: 50px;
`

const ContactPageHeading = styled.h1`
    font-family: 'Libre Franklin';
    font-size: 20px;
    font-weight: normal;
    color: #212b36;
    margin-bottom: 0px;

    ${breakpoint("sm")`
        font-size: 36px;
    `}
`

const ContactPageSubHeading = styled.h2`
    font-family: 'Libre Franklin';
    font-size: 20px;
    font-weight: normal;
    color: #919eab;

    ${breakpoint("sm")`white
        font-size: 36px;
    `}
`

const InputDiv = styled.div`
    background-color: #ededed;
    height: 670px;
    margin-top: 50px;

    ${breakpoint("sm")`
        height: 950px;
    `}
`

const MobileFieldInput = styled.input`
    margin-top:20px;
    width: 100%;
    line-height: 2.5em;
    background-color: #ffffff;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    font-family: 'Libre Franklin';
    font-size: 14px;
    border-radius: 20px;
    padding-left: 15px;
    color: #919eab;

    ::placeholder {
        color: #919eab;
        opacity: 1;
    }

    ${breakpoint("sm")`
        font-size: 18px;
        margin-top: 40px;
    `}
`

const MobileFieldTextArea = styled.textarea`
    width: 100%;
    margin-top:20px;
    line-height: 2.5em;
    height: 70px;
    background-color: #fffff;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    font-family: 'Libre Franklin';
    font-size: 14px;
    border-radius: 20px;
    padding-left: 15px;
    color: #919eab;

    ::placeholder {
        color: #919eab;
        opacity: 1;
    }

    ${breakpoint("sm")`
        font-size: 18px;
        margin-top: 40px;
    `}
`


const MobileFieldSelect = styled.select`
    width: 100%;
    line-height: 2.5em;
    margin-top:20px;
    height: 50px;
    background-color: #ffffff;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    font-family: 'Libre Franklin';
    font-size: 14px;
    border-radius: 25px;
    color: #919eab;
    padding-left: 15px;
    
    ${breakpoint("sm")`
        font-size: 18px;
        margin-top: 40px;
    `}
`

const SendButton = styled.button`
    color: white;
    margin-top:20px;
    font-size: 14px;
    font-family: 'Libre Franklin';
    font-weight: bold;
    background-color: #808080;
    border: none;
    border-radius: 25px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;

    :focus {
        outline: 0;
    }

    ${breakpoint("sm")`
        font-size: 18px;
        margin-top: 40px;
    `}
`

const InputRow = styled(Row)`
    padding-top: 5px;
`

const SendButtonRow = styled(Col)`
    text-align: center;
    margin-top: 16px;
`

const AlertDiv = styled.div`
    text-align: center;
    font-size: 14px;
    font-family: 'Libre Franklin';
`

class Contact extends React.Component {

  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.state = {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        projectIdea: "",
        typeOfProject: "", //drop down menu
        description: "",
        industry: "", //drop down menu
        additionalInfo: "",
      },
      submitDisabled: true,
      sendSuccess: false,
      sendFailure: false,

      componentDidMount() {
        const script = document.createElement("script")

        script.src = "//js.hs-scripts.com/7845924.js"
        script.async = true
        script.defer = true

        document.body.appendChild(script)
      },
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    let form = { ...this.state.form }
    form[name] = value
    this.setState({ form }, () => {
      this.getDisabled()
    })
  }

  getDisabled = () => {
    if (
      this.state.form.firstName !== "" &&
      this.state.form.lastName !== "" &&
      this.state.form.email !== "" &&
      this.state.form.projectIdea !== "" &&
      this.state.form.typeOfProject !== "" &&
      this.state.form.description !== ""
    ) {
      this.setState({ submitDisabled: false })
    } else {
      this.setState({ submitDisabled: true })
    }
  }

  sendButtonClick = async () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    const url = 'https://api.hsforms.com/submissions/v3/integration/submit/7845924/3316051e-5da0-4f27-ad71-bbc15a9a19b0';
    const data = {
      fields: [
        {
          'name': 'firstname',
          'value': this.state.form.firstName
        },
        {
          'name': 'lastname',
          'value': this.state.form.lastName
        },
        {
          'name': 'email',
          'value': this.state.form.email
        },
        {
          'name': 'project_idea',
          'value': this.state.form.projectIdea
        },
        {
          'name': 'type_of_project',
          'value': this.state.form.typeOfProject
        },
        {
          'name': 'project_description',
          'value': this.state.form.description
        },
        {
          'name': 'industry',
          'value': this.state.form.industry
        },
        {
          'name': 'message',
          'value': this.state.form.additionalInfo
        },
      ],
      context: {
         'pageUri': 'calicologic.com/contact/' ,
         'pageName': 'Contact Us'
      }
    }
    await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(data)
    }).catch((err) => Promise.reject(Error(`Upload Error: ${err}`)))
    // Reset inputs
    this.setState({
      sendSuccess: true,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        projectIdea: "",
        typeOfProject: "",
        additionalInfo: "",
        description:"",
        industry: "", //drop down menu
      },
      submitDisabled: true
    })
  }

  render() {
    let sendButton = this.state.submitDisabled ? "#808080" : "#e66012" //this is here to change the colour of the button
    //so when it is disabled, it's grey and when it is active, it is orange

    return (
      <ContactDiv>
        <Container>
          <Row>
            <Col>
              <ContactPageHeading>
                We want to talk to you
              </ContactPageHeading>
            </Col>
          </Row>
          <Row>
            <Col>
              <ContactPageSubHeading>
                Let us know what you’re thinking
              </ContactPageSubHeading>
            </Col>
          </Row>
        </Container>
        <InputDiv>
          <Container>
            <InputRow>
              <Col>
                <MobileFieldInput
                  type="text"
                  placeholder="First name (required)*"
                  name="firstName"
                  value={this.state.form.firstName}
                  onChange={this.handleInputChange} />
              </Col>
            </InputRow>
            <InputRow>
              <Col>
                <MobileFieldInput
                  type="text"
                  placeholder="Last name (required)*"
                  name="lastName"
                  value={this.state.form.lastName}
                  onChange={this.handleInputChange} />
              </Col>
            </InputRow>
            <InputRow>
              <Col>
                <MobileFieldInput
                  type="email"
                  placeholder="Email address (required)*"
                  name="email"
                  value={this.state.form.email}
                  onChange={this.handleInputChange} />
              </Col>
            </InputRow>
            <InputRow>
              <Col>
                <MobileFieldInput
                  type="text"
                  placeholder="Project idea title (required)*"
                  name="projectIdea"
                  value={this.state.form.projectIdea}
                  onChange={this.handleInputChange} />
              </Col>
            </InputRow>
            <InputRow>
              <Col>
                <MobileFieldSelect
                  name="typeOfProject"
                  placeholder="Select the type of project (required)*"
                  value={this.state.form.typeOfProject}
                  onChange={this.handleInputChange}
                >
                  <option default hidden>Select the type of project (required)*</option>
                  <option> Web Application</option>
                  <option> Integration</option>
                  <option> Mobile Application</option>
                  <option> Professional Services</option>
                  <option> All of the above</option>
                </MobileFieldSelect>
              </Col>
            </InputRow>
            <InputRow>
              <Col>
                <MobileFieldTextArea
                  type="text"
                  placeholder="Project description (required)*"
                  name="description"
                  value={this.state.form.description}
                  onChange={this.handleInputChange} />
              </Col>
            </InputRow>

            <InputRow>
              <Col>
                <MobileFieldSelect
                  name="industry"
                  placeholder="Industry (optional)"
                  value={this.state.form.industry}
                  onChange={this.handleInputChange}
                >
                  <option selected hidden> Industry (optional)</option>
                  <option> Agriculture, Forestry and Fishing</option>
                  <option> Mining</option>
                  <option> Construction</option>
                  <option> Manufacturing</option>
                  <option> Transportation, Communications, Electric, Gas and Sanitary service</option>
                  <option> Wholesale Trade</option>
                  <option> Retail Trade</option>
                  <option> Finance, Insurance and Real Estate</option>
                  <option> Services</option>
                  <option> Public Administration</option>
                </MobileFieldSelect>
              </Col>
            </InputRow>


            <InputRow>
              <Col>
                <MobileFieldInput
                  type="text"
                  placeholder="Additional information (optional)"
                  name="additionalInfo"
                  value={this.state.form.additionalInfo}
                  onChange={this.handleInputChange} />
              </Col>
            </InputRow>

            <InputRow>
              <Col>
                <AlertDiv>
                  <Alert color="success" isOpen={this.state.sendSuccess}>
                    Your request has been received.
                    Someone will be in touch shortly.
                  </Alert>
                </AlertDiv>
              </Col>
            </InputRow>
            <SendButtonRow>
              <SendButton
                id="sendButton"
                style={{ backgroundColor: sendButton }}
                className={sendButton}
                disabled={this.state.submitDisabled}
                onClick={() => this.sendButtonClick()}>
                Send
              </SendButton>
            </SendButtonRow>
          </Container>
        </InputDiv>
      </ContactDiv>
    )
  }
}

export default Contact
